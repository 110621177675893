import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueProgressBar from "vue-progressbar";
import VTooltip from "v-tooltip";
import App from "./App.vue";
import VueGtm from "@gtm-support/vue2-gtm";
import ToggleButton from "vue-js-toggle-button";
import { AppCommand, Bridge } from "@/api/bridge";

Vue.config.productionTip = false;

const options = {
  color: "#144ad8",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, options);
Vue.use(VTooltip);
Vue.use(ToggleButton);

Vue.directive("visible", function(el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_KEY,
  enabled: true,
  debug: process.env.NODE_ENV === "development",
  loadScript: true,
  vueRouter: router
});

Vue.prototype.$bridge = new Bridge();

window.addEventListener("DOMContentLoaded", () => {
  Vue.prototype.$bridge.requestToApp(AppCommand.REGISTER_ON_RESUME, {
    objectMethodName: "EbmpRegister.resume",
    handler: () => {}
  });
});

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
