<template>
  <v-dialog v-model="isGlobalDialogShow" width="500" persistent>
    <v-card class="dialog-global">
      <div class="dialog-global-contents">
        <h1 class="dialog-global-title" v-html="globalDialog.title" v-if="globalDialog.title"></h1>
        <p class="dialog-global-message" v-html="globalDialog.message" v-if="globalDialog.message"></p>
      </div>
      <div class="dialog-global-btns">
        <v-btn block x-large depressed @click.native="handleClick" v-if="globalDialog.isCancel">취소</v-btn>
        <v-btn color="primary" block x-large depressed @click.native="handleClick">확인</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import "~@/styles/components/_global-dialog";
</style>

<script>
import { uiGetters, uiActions } from "@/store";
import { AppCommand } from "@/api/bridge";

export default {
  computed: {
    ...uiGetters(["globalDialog", "isGlobalDialogShow"])
  },
  methods: {
    ...uiActions(["setGlobalDialog"]),
    handleClick() {
      if (this.globalDialog.isGotoAppMain) {
        this.$bridge.requestToApp(AppCommand.REQUEST_GO_HOME, {});
      }

      this.setGlobalDialog({
        isShow: false
      });

      setTimeout(() => {
        this.setGlobalDialog({
          message: ""
        });
      }, 100);
    }
  }
};
</script>
