export const ROUTES_NAME = {
  CARD: "Card",
  CARD_DETAIL: "CardDetail",
  CARD_PROMOTION: "CardPromotion",
  LOAN: "Loan",
  LOAN_PROMOTION: "LoanPromotion",
  LOAN_TEMPLATE: "LoanTemplate",
  INSURANCE: "Insurance",
  INSURANCE_TEMPLATE: "InsuranceTemplate",
  TERMS: "Terms",
  REDIRECT: "Redirect",
  PRODUCT_PROMOTION: "ProductPromotion",
  MAINTENANCE: "Maintenance"
};

//export const BASE_ROOT = "ocb";
