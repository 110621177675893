<template>
  <v-dialog v-model="isGlobalAgreeDialogShow" persistent scrollable content-class="dialog-agree" style="z-index:10001;">
    <div id="dialog-agree-container">
      <div class="icon-wrap">
        <div class="icon-inner">
          <div class="icon icon-thumb-up"></div>
        </div>
      </div>
      <v-card class="dialog-agree-wrap">
        <v-card-text class="dialog-agree-inner">
          <h1 class="dialog-agree-title">OK캐쉬백 개인정보 제3자 제공 동의</h1>
          <p class="dialog-agree-text">
            OK캐쉬백 고객님께 적합한 금융 상품,<br />
            실시간 금융 혜택 등 중요한 정보를 알려드려요
          </p>
          <p class="dialog-agree-link" @click="openTerms">개인정보 제3자 제공 동의 (선택)</p>
        </v-card-text>
        <div class="dialog-agree-actions">
          <button type="button" class="btn" @click.prevent="close(true)">동의 안 함</button>
          <button type="button" class="btn btn-confirm alarm" @click.prevent="register" :disabled="isProcessing">동의</button>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { uiGetters, userGetters, userActions, uiActions } from "@/store";
import { AppCommand } from "@/api/bridge";
import { addSearchQuery, isSuccess, setLocalStorageItem } from "@/helpers";
import { OCB_TRACK_ID, WV_TITLE } from "@/const";
import { gaTracker, getTraceQuery } from "@/helpers/tracer";

export default {
  name: "DialogAgree",
  data() {
    return {
      form: {
        trdInfo: true
      },
      isProcessing: false
    };
  },
  computed: {
    ...uiGetters(["isGlobalAgreeDialogShow", "agreeCookieName"]),
    ...userGetters(["agreeInfoForm", "isTrdInfo", "isInitialUser", "trackCdType", "isNeedAgree"])
  },
  methods: {
    ...uiActions(["setGlobalAgreeDialog", "setGlobalSnackbar"]),
    ...userActions(["fetchUser", "setAgreeInfo"]),
    async close(fromBtn) {
      // 취소면 저장 안하는 것 > 기존 등록된 사용자는 그대로 둔다. 미등록된 사용자는 false로 업데이트 한다.
      // 기등록된 사용자가 아니면(사용자 정보가 없다면), false로 사용자 업데이트 한다.
      // 주석 처리해도 무방하나 혹시나 싶어 남겨둠. > 각 페이지에서 우선 미동의자로 우리쪽 DB에 쌓도록 처리함.
      if (this.isInitialUser) {
        await this.setAgreeInfo({
          agreeInfoForm: {
            trdInfo: false
          }
        });
      }

      if (fromBtn) {
        this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.AGREE_POPUP, action_id: "bottom_tap.disagreebtn" } });

        gaTracker({
          category: `약관 동의 모달`,
          action: `동의 안 함 클릭`,
          label: `${this.trackCdType}`
        });
      }

      setLocalStorageItem(this.agreeCookieName, "end", 24 * 60 * 60 * 1000);
      this.setGlobalAgreeDialog({ isShow: false });
    },
    openTerms() {
      //const { termPath } = e.currentTarget.dataset; // termsTitle

      this.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
        url: addSearchQuery(`${process.env.VUE_APP_URI}/terms`, getTraceQuery()),
        title: WV_TITLE.TERMS
      });

      // 정보 동의/해제 페이지에서 사용자 정보 변경이 일어나므로, 사용자 정보에 대한 refresh가 필요하다. ex) 사용자 이름
      this.$bridge.requestToApp(AppCommand.REGISTER_ON_RESUME, {
        objectMethodName: "EbmpRegister.resume",
        handler: async () => {
          await this.fetchUser();

          // 사용자가 이미 동의를 해서 이제 동의 모달이 필요 없는 경우 닫아 준다.
          if (!this.isNeedAgree) {
            await this.close();
          }
        }
      });
    },
    async register() {
      this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.AGREE_POPUP, action_id: "bottom_tap.agreebtn" } });

      gaTracker({
        category: `약관 동의 모달`,
        action: `동의 클릭`,
        label: `${this.trackCdType}`
      });

      const data = await this.setAgreeInfo({
        agreeInfoForm: {
          ...this.form
        }
      });

      if (isSuccess(data)) {
        await this.close();
      } else {
        this.setGlobalSnackbar({ message: "정보 저장에 실패했습니다." });
      }
    }
  },
  mounted() {
    this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.AGREE_POPUP } });

    gaTracker({
      category: `약관 동의 모달`,
      action: `보기`,
      label: `${this.trackCdType}`
    });
  }
};
</script>

<style lang="scss" scoped>
::v-deep .dialog-agree {
  &-wrap {
    border-radius: 8px;
  }

  position: relative;
  // 375 기준 디자인 추가됨
  $breakpoints-percent: 16/14;
  box-shadow: none;

  .icon {
    &-wrap {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 100;
      width: 72px;
      height: 72px;
      padding: 9px;
      border-radius: 500em;
      background-color: #fff;
      transform: translate(-50%, 0);
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 500em;
      background-color: #fff6da;
    }
  }

  &-inner {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    -webkit-overflow-scrolling: touch;
    padding: rem(42px * $breakpoints-percent) rem(20px * $breakpoints-percent) rem(20px * $breakpoints-percent) !important;
  }

  &-title {
    margin: 0;
    @include bold_16_24(-0.014em);
    text-align: center;
    color: $color-grey-6;
  }

  &-text {
    margin-top: 8px;
    @include normal_13_19();
    text-align: center;
    color: $color-grey-4;
  }

  &-link {
    margin-top: 8px;
    @include normal_13_22();
    text-align: center;
    text-decoration: underline;
    color: $color-grey-3;

    cursor: pointer;
  }

  &-list {
    margin: 15px 0 0;
    padding: 0 rem(24px);
    border: 1px solid rgba(231, 231, 231, 0.7);
    border-radius: 6px;
    list-style: none;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #f5f5f5;
    font-size: 13px;
    line-height: (22/13);
    letter-spacing: -0.025em;

    &:first-child {
      border-width: 0;
    }

    &.disabled {
      opacity: 0.5;
    }

    .text {
      cursor: pointer;
    }

    &.active {
      .text {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .custom-check {
      input {
        display: none;
      }

      input:checked + .check {
        border-color: #ff7c00;

        &:after {
          display: block;
        }
      }

      .check {
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border: 2px solid #f5f5f5;
        border-radius: 500em;
        vertical-align: top;

        &:after {
          display: none;
          width: (22px/2);
          height: (16px/2);
          background-image: url(~@/assets/icons/icon-dialog-check@2x.png);
          background-size: contain;
          content: "";
        }
      }
    }
  }

  &-actions {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    display: flex;
    font-size: 16px;
    line-height: (24/16);
    text-align: center;
    letter-spacing: -0.014em;

    .btn {
      flex: 1;
      height: 60px;
      border: 1px solid #f5f5f5;

      &:first-child {
        border-left-width: 0;
      }

      &-confirm {
        font-weight: 700;
        color: #ff7c00;

        &[disabled] {
          background-color: #fcfcfc;
          color: #d8d8d8;
        }
      }
    }
  }
}

#dialog-agree-container {
  position: relative;
  padding-top: 34px;

  .dialog-agree-inner {
    color: #666;
  }
}
</style>
