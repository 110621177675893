<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <global-dialog />
    <dialog-push v-if="isGlobalPushDialogShow" :card-type="productType" :data="pushInfo" />
    <dialog-agree v-if="isGlobalAgreeDialogShow" />
    <global-snackbar />
    <bridge-dialog />
  </v-app>
</template>

<style lang="scss">
@import "~@/styles/app";
</style>

<script>
import GlobalDialog from "@/components/GlobalDialog.vue";
import DialogPush from "@/components/DialogPush";
import DialogAgree from "@/components/DialogAgree";
import { userGetters, uiActions, uiGetters } from "@/store";
import GlobalSnackbar from "@/components/GlobalSnackbar";
import BridgeDialog from "@/components/BridgeDialog";

export default {
  name: "App",
  components: {
    GlobalSnackbar,
    GlobalDialog,
    DialogPush,
    DialogAgree,
    BridgeDialog
  },
  computed: {
    ...userGetters(["pushInfo", "productType", "token"]),
    ...uiGetters(["isGlobalPushDialogShow", "isGlobalAgreeDialogShow"])
  },
  methods: {
    ...uiActions(["setGlobalDialog"])
  }
};
</script>
