export const MEDIA_SEQ = 3; // skt: 1, lgu: 2, ocb: 3

// T011
export const CATEGORY_CD = {
  CARD: "CARD",
  INSU: "INSU",
  LOAN: "LOAN"
};

export const WALK_IN_TRACK_CD_TYPE = "C001";

export const DEFAULT_TITLE = "상세보기";

export const IS_DEV_ENV = process.env.VUE_APP_ENV === "staging" || process.env.VUE_APP_ENV === "development";

export const IS_LOCAL_ENV = process.env.VUE_APP_ENV === "development";

export const TEST_TOKEN = "T0b170dfc695b4b37";
export const VALID_TEST_TOKEN = "T663b078026ee48f2";

export const OCB_TRACK_ID = {
  INDEX: "/earnpoint/loanproducts",
  PROMOTION: "/earnpoint/loanproducts/detail",
  THIRD_AGREE: "/earnpoint/loanproducts/thirdagree",
  THIRD_AGREE_POPUP: "/earnpoint/loanproducts/thirdagree/popup",
  AGREE_POPUP: "/earnpoint/loanproducts/popup"
};

export const TYPE_DICT = { LOAN: "L" };
export const SUB_TYPE_DICT = { promotion_seq: "PRM", it_seq: "IT" }; // DO NOT CHANGE KEY, IT MUST BE MATCHED WITH QUERY

export const WV_TITLE = {
  TERMS: "개인정보 제3자 제공동의"
};

// 고유 키값으로 사용되는 프로모션, 상세의 카테고리값.(공통코드 번호 : C023, prodSeq는 임시 페이지라는 의미로 T를 사용)
export const CREATIVE_DTL_TYPE = {
  CARD: "C",
  LOAN: "L",
  PROMOTION: "P",
  PRODUCT: "T", // 과거 전북은행용
  IN_APP_TEMPLATE: "I"
};

export const DESTINATION_TYPE = {
  OUT_LINK: "C",
  IN_APP: "I",
  DETAIL: "D",
  PROMOTION: "P",
  IN_APP_TEMPLATE: "T"
};

// warning: 통계에서 값이 사용되므로 임의로 변경하지 말 것.
export const OS_TYPE = {
  IOS: "iOS",
  AND: "AND"
};
