import { createNamespacedHelpers } from "vuex";

export default {
  state: {
    agreeCookieName: "ebmpAgreeCookie",
    globalDialog: {
      isShow: false,
      title: "",
      message: "",
      isGotoAppMain: false,
      isCancel: false
    },
    globalSnackbar: {
      isShow: false,
      message: "",
      timeout: "3000"
    },
    globalAgreeDialog: {
      isShow: false,
      type: "recommend" //recommend or alarm
    },
    globalPushDialog: {
      isShow: false,
      isAlreadyOpen: false
    },
    bridgeDialog: {
      isShow: false,
      callback: null
    },
    webViewTriggered: false
  },
  getters: {
    globalDialog(state) {
      return state.globalDialog;
    },
    isGlobalDialogShow(state) {
      return state.globalDialog.isShow;
    },
    globalSnackbar(state) {
      return state.globalSnackbar;
    },
    isGlobalSnackbarShow(state) {
      return state.globalSnackbar.isShow;
    },
    bridgeDialog(state) {
      return state.bridgeDialog;
    },
    isBridgeDialogShow(state) {
      return state.bridgeDialog.isShow;
    },
    isRecommend(state) {
      return state.globalAgreeDialog.type === "recommend";
    },
    isGlobalAgreeDialogShow(state) {
      return state.globalAgreeDialog.isShow;
    },
    isGlobalPushDialogShow(state) {
      return state.globalPushDialog.isShow;
    },
    isGlobalPushDialogAlreadyOpen(state) {
      return state.globalPushDialog.isAlreadyOpen;
    },
    agreeCookieName(state) {
      return state.agreeCookieName;
    },
    webViewTriggered(state) {
      return state.webViewTriggered;
    }
  },
  mutations: {
    SET_GLOBAL_DIALOG(state, payload) {
      state.globalDialog = {
        ...state.globalDialog,
        ...payload
      };
    },
    SET_GLOBAL_SNACKBAR(state, payload) {
      state.globalSnackbar = {
        ...state.globalSnackbar,
        ...payload
      };
    },
    SET_BRIDGE_DIALOG(state, payload) {
      state.bridgeDialog = {
        ...state.bridgeDialog,
        ...payload
      };
    },
    SET_GLOBAL_AGREE_DIALOG(state, payload) {
      state.globalAgreeDialog = {
        ...state.globalAgreeDialog,
        ...payload
      };
    },
    SET_GLOBAL_PUSH_DIALOG(state, payload) {
      state.globalPushDialog = {
        ...state.globalPushDialog,
        ...payload
      };
    },
    SET_WEBVIEW_TRIGGERED(state, payload) {
      state.webViewTriggered = payload;
    }
  },
  actions: {
    setGlobalPushDialog({ commit }, payload) {
      commit("SET_GLOBAL_PUSH_DIALOG", payload);
    },
    setGlobalAgreeDialog({ commit }, payload) {
      commit("SET_GLOBAL_AGREE_DIALOG", payload);
    },
    setGlobalDialog({ commit }, payload) {
      commit("SET_GLOBAL_DIALOG", payload);
    },
    setGlobalSnackbar({ commit }, payload) {
      commit("SET_GLOBAL_SNACKBAR", payload);
    },
    setBridgeDialog({ commit }, payload) {
      commit("SET_BRIDGE_DIALOG", payload);
    },
    setWebviewTriggered({ commit }, payload) {
      commit("SET_WEBVIEW_TRIGGERED", payload);
    }
  }
};
const { mapGetters: uiGetters, mapActions: uiActions } = createNamespacedHelpers("ui");

export { uiGetters, uiActions };
