import { createNamespacedHelpers } from "vuex";
import { showFailMessage } from "@/api/handler.js";
import { CATEGORY_CD, MEDIA_SEQ } from "@/const";
import { getBanner, getLoanBenefitList, getCategoryList, getLoanTopSupport } from "@/api";

export default {
  state: {
    mainBannerList: [],
    categoryList: [],
    topSupportList: [],
    benefitList: []
  },
  getters: {
    mainBannerList: (state) => {
      return state.mainBannerList;
    },
    categoryList: (state) => {
      return state.categoryList;
    },
    topSupportList: (state) => {
      if (state.topSupportList) {
        state.topSupportList.forEach((element) => {
          const { supportText } = element;

          if (supportText) {
            element.supportText = `대출이자 ${parseInt(supportText, 10)}% 지원 `;
          }
        });
      }

      return state.topSupportList;
    },
    benefitList: (state) => {
      return state.benefitList;
    }
  },
  mutations: {
    SET_MAIN_BANNER_LIST(state, mainBannerList) {
      state.mainBannerList = mainBannerList;
    },
    SET_CATEGORY_LIST(state, categoryList) {
      categoryList.forEach((element, i) => {
        element.isIcon = false;
        element.isToggle = false;
        element.isClicked = i === 0;
      });

      state.categoryList = categoryList;
    },
    SET_TOP_SUPPORT_LIST(state, topSupportList) {
      state.topSupportList = topSupportList;
    },
    SET_BENEFIT_LIST(state, benefitList) {
      state.benefitList = benefitList;
    }
  },
  actions: {
    async fetchLoanCategory({ commit, rootGetters }) {
      const { data } = await getCategoryList({
        trackCd: rootGetters["user/trackCd"],
        condition: {
          osType: rootGetters["user/osType"],
          mediaSeq: MEDIA_SEQ,
          category: CATEGORY_CD.LOAN
        } // 대출 카테고리
      });

      const { isSucceed, resultMessage, resultCode, result } = data;

      if (isSucceed) {
        if (!result) return;

        const { list } = result;

        commit("SET_CATEGORY_LIST", list);
      } else {
        showFailMessage(commit, { resultMessage, resultCode });
      }

      return data;
    },

    async fetchLoanTopSupport({ commit, rootGetters }) {
      // 20 : 대출 상단, AF01 : 대출
      const { data } = await getLoanTopSupport({
        trackCd: rootGetters["user/trackCd"],
        condition: { osType: rootGetters["user/osType"], mediaSeq: MEDIA_SEQ }
      });

      const { isSucceed, resultMessage, resultCode, result } = data;

      if (isSucceed) {
        if (!result) return;

        const { list } = result;

        commit("SET_TOP_SUPPORT_LIST", list);
      } else {
        showFailMessage(commit, { resultMessage, resultCode });
      }

      return data;
    },

    async fetchLoanBanner({ commit, rootGetters }) {
      //const { infoUse } = mediaSeq === MEDIA_SEQ ? rootState.user.agreeInfo : { infoUse: "N" };

      const GET_BANNER_RANDOM_EXPOSE_OPTION = "1";
      // 20 : 대출 상단, AF01 : 대출
      const { data } = await getBanner({
        trackCd: rootGetters["user/trackCd"],
        condition: {
          osType: rootGetters["user/osType"],
          mediaSeq: MEDIA_SEQ,
          placeSeq: "20",
          adCateCd: "AF01",
          option: GET_BANNER_RANDOM_EXPOSE_OPTION // 랜덤 노출
        }
      });
      const { isSucceed, resultMessage, resultCode, result } = data;

      if (isSucceed) {
        if (!result) return;

        const { list } = result;

        commit("SET_MAIN_BANNER_LIST", list);
      } else {
        showFailMessage(commit, { resultMessage, resultCode });
      }

      return data;
    },
    async fetchLoanBenefitList({ commit, rootGetters }, { categoryCodeList = [], price, point, creditType, orderType }) {
      const { data } = await getLoanBenefitList({
        trackCd: rootGetters["user/trackCd"],
        condition: {
          osType: rootGetters["user/osType"],
          categoryCodeList,
          orderType,
          price,
          point,
          creditType,
          mediaSeq: MEDIA_SEQ
        }
      });

      const { isSucceed, resultMessage, resultCode, result } = data;

      if (isSucceed) {
        if (!result) return;
        const { list } = result;

        commit("SET_BENEFIT_LIST", list);
      } else {
        showFailMessage(commit, { resultMessage, resultCode });
      }

      return data;
    }
  }
};

const { mapGetters: loanGetters, mapActions: loanActions } = createNamespacedHelpers("loan/loan");

export { loanGetters, loanActions };
