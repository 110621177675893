import { AppCommand } from "@/api/bridge";
import { addSearchQuery, isOurService } from "@/helpers/index";
import { ROUTES_NAME } from "@/const/router";
import store from "@/store";
import VM from "@/main";
import { CREATIVE_DTL_TYPE, DEFAULT_TITLE, DESTINATION_TYPE, SUB_TYPE_DICT, TYPE_DICT } from "@/const";
import { getTraceQuery } from "@/helpers/tracer";

export const openPushWebview = ({ type, subType, seq, ads_seq }) => {
  console.log("openPushWebview", { type, subType, seq, ads_seq });

  switch (type) {
    case TYPE_DICT.LOAN: {
      // 대출
      switch (subType) {
        case SUB_TYPE_DICT.promotion_seq: {
          // 페이지 이동
          VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
            url: addSearchQuery(`${window.location.origin}/loan/promotion/${seq}`, getTraceQuery({ ads_seq, from_push: "Y" })),
            title: DEFAULT_TITLE
          });

          // 현재 주소를 변경한다.
          VM.$router.replace({ name: ROUTES_NAME.LOAN, query: getTraceQuery() });
          break;
        }
        case SUB_TYPE_DICT.it_seq: {
          VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
            url: addSearchQuery(`${window.location.origin}/loan/template/${seq}`, getTraceQuery({ ads_seq, from_push: "Y" })),
            title: DEFAULT_TITLE
          });

          VM.$router.replace({ name: ROUTES_NAME.LOAN, query: getTraceQuery() });
          break;
        }
      }
    }
  }
};

export const getDestinationType = ({ destinationType, cardSeq, loanSeq, promotionSeq, itSeq, destinationUrl }) => {
  if (destinationType) return destinationType;

  if (cardSeq || loanSeq) return DESTINATION_TYPE.DETAIL;
  if (promotionSeq) return DESTINATION_TYPE.PROMOTION;
  if (itSeq) return DESTINATION_TYPE.IN_APP_TEMPLATE;
  if (destinationUrl) return DESTINATION_TYPE.OUT_LINK; // 근본적으로는 알 수 없음 - 아웃링크일 수도 있고 인앱 사이트링크일 수도 있다.
};

export const move = ({ type, loanSeq, cardSeq, promotionSeq, itSeq, destinationType, destinationUrl, gpMappSeq, adsSeq, payYn }, override) => {
  const underScoreQuery = getTraceQuery({ gpMappSeq, adsSeq, payYn });

  console.log("underScoreQuery", underScoreQuery);

  destinationType = getDestinationType({ destinationType, cardSeq, loanSeq, promotionSeq, itSeq, destinationUrl });

  // call override method
  if (override && override[destinationType]) {
    override[destinationType]();
    return;
  }

  switch (destinationType) {
    case DESTINATION_TYPE.OUT_LINK: {
      // 외부 페이지
      if (!destinationUrl) {
        console.error("destinationUrl is not defined");
        return;
      }

      // 외부 브라우저 호출
      store.dispatch(
        "ui/setBridgeDialog",
        {
          isShow: true,
          callback: () => {
            VM.$bridge.requestToApp(AppCommand.START_BROWSER, {
              url: destinationUrl
            });
          }
        },
        { root: true }
      );
      break;
    }
    case DESTINATION_TYPE.IN_APP: {
      // 내부 페이지
      if (!destinationUrl) {
        console.error("destinationUrl is not defined");
        return;
      }

      // 인앱 지정 페이지
      if (isOurService(destinationUrl)) {
        VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
          url: addSearchQuery(destinationUrl, underScoreQuery),
          title: DEFAULT_TITLE
        });
      } else {
        VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
          url: destinationUrl,
          title: DEFAULT_TITLE
        });
      }
      break;
    }
    case DESTINATION_TYPE.DETAIL: {
      // 상세 페이지, 대출은 없음
      // 카드
      if (type === CREATIVE_DTL_TYPE.CARD) {
        if (!cardSeq) {
          console.error("cardSeq is not defined");
          return;
        }

        VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
          url: addSearchQuery(`${window.location.origin}/card/${cardSeq}`, underScoreQuery),
          title: DEFAULT_TITLE
        });
      }
      break;
    }
    case DESTINATION_TYPE.PROMOTION: {
      // 프로모션 페이지
      if (!promotionSeq) {
        console.error("promotionSeq is not defined");
        return;
      }

      switch (type) {
        case CREATIVE_DTL_TYPE.CARD: {
          VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
            url: addSearchQuery(`${window.location.origin}/card/promotion/${promotionSeq}`, underScoreQuery),
            title: DEFAULT_TITLE
          });
          break;
        }
        case CREATIVE_DTL_TYPE.LOAN: {
          VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
            url: addSearchQuery(`${window.location.origin}/loan/promotion/${promotionSeq}`, underScoreQuery),
            title: DEFAULT_TITLE
          });
          break;
        }
      }
      break;
    }
    case DESTINATION_TYPE.IN_APP_TEMPLATE: {
      // 템플릿 페이지
      if (!itSeq) {
        console.error("itSeq is not defined");
        return;
      }

      switch (type) {
        case CREATIVE_DTL_TYPE.CARD: {
          VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
            url: addSearchQuery(`${window.location.origin}/card/template/${itSeq}`, underScoreQuery),
            title: DEFAULT_TITLE
          });
          break;
        }
        case CREATIVE_DTL_TYPE.LOAN: {
          VM.$bridge.requestToApp(AppCommand.REQUEST_WEBVIEW, {
            url: addSearchQuery(`${window.location.origin}/loan/template/${itSeq}`, underScoreQuery),
            title: DEFAULT_TITLE
          });
          break;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
};
