<template>
  <v-dialog v-model="isGlobalPushDialogShow" max-width="100%" persistent scrollable transition="dialog-bottom-transition" content-class="dialog-push" @click:outside="close" style="z-index:10001;">
    <button type="button" @click="close" class="dialog-push-close"><i class="icon icon-close"></i></button>
    <v-card class="dialog-push-wrap">
      <v-card-text class="dialog-push-inner" v-if="isCard">
        <h1 class="dialog-push-title">
          <!--          <strong>{{ custName }}님을 위해</strong><br />-->
          <!--          <span v-if="data.titleText">{{ data.titleText }}</span>-->
          <!--          <span v-else>PASS가 혜택을 더 드립니다.</span>-->
          <strong v-html="data.titleText1"></strong><br />
          <span v-html="data.titleText2"></span>
        </h1>
        <ul>
          <dialog-push-card v-for="(item, index) in data.list" :key="index + Date.now()" v-bind="item" :data-index="index" @click-item="onClick" @display-item="onDisplay"></dialog-push-card>
        </ul>
      </v-card-text>
      <v-card-text class="dialog-push-inner" v-else-if="isLoan">
        <h1 class="dialog-push-title">
          <!--          <strong>{{ custName }}님을 위해</strong><br />-->
          <!--          <span v-if="data.titleText">{{ data.titleText }}</span>-->
          <!--          <span v-else>대출이자를 더 지원해 드립니다.</span>-->
          <strong v-html="data.titleText1"></strong><br />
          <span v-html="data.titleText2"></span>
        </h1>
        <ul>
          <dialog-push-loan v-for="(item, index) in data.list" :key="index + Date.now()" v-bind="item" :data-index="index" @click-item="onClick" @display-item="onDisplay"></dialog-push-loan>
        </ul>
      </v-card-text>
      <v-card-text class="dialog-push-inner" v-else>
        <h1 class="dialog-push-title">
          <strong v-html="data.titleText1"></strong><br />
          <span v-html="data.titleText2"></span>
        </h1>
        <ul>
          <dialog-push-insurance v-for="(item, index) in data.list" :key="index + Date.now()" v-bind="item" :data-index="index" @click-item="onClick" @display-item="onDisplay"></dialog-push-insurance>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import "~@/styles/components/_dialog-push";
</style>

<script>
import { uiActions, uiGetters, userGetters } from "@/store";
import DialogPushCard from "./DialogPushCard";
import DialogPushLoan from "./DialogPushLoan";
import DialogPushInsurance from "./DialogPushInsurance";
import { traceEvent } from "@/helpers/tracer";
import { move } from "@/helpers/move";
import { CREATIVE_DTL_TYPE } from "@/const";

export default {
  name: "DialogPush",
  components: {
    DialogPushCard,
    DialogPushLoan,
    DialogPushInsurance
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    cardType: {
      type: String,
      default: "CARD"
    },
    data: {
      type: Object,
      default: function() {}
    }
  },
  computed: {
    ...userGetters(["trackCd", "osType"]),
    ...uiGetters(["isGlobalPushDialogShow"]),
    isCard() {
      return this.cardType === "CARD";
    },
    isLoan() {
      return this.cardType === "LOAN";
    },
    custName() {
      if (this.data.custNm && this.data.custNm !== "") {
        return this.data.custNm;
      } else {
        return "PASS고객";
      }
    }
  },
  methods: {
    ...uiActions(["setGlobalPushDialog"]),
    close() {
      this.setGlobalPushDialog({ isShow: false, isAlreadyOpen: true });
    },
    onDisplay(el) {
      const { index } = el.dataset;
      let { gpMappSeq, adsSeq, payYn } = { ...this.data.list[index] };
      traceEvent({ gpMappSeq, adsSeq, payYn, eventType: "I003" });
    },
    onClick(e) {
      const { index } = e.currentTarget.dataset;

      this.close();
      traceEvent({ ...this.data.list[index], eventType: "C003" });

      //{ cardSeq, promotionSeq, destinationType, destinationUrl, gpMappSeq, adsSeq, payYn }
      move({ ...this.data.list[index], type: this.isCard ? CREATIVE_DTL_TYPE.CARD : CREATIVE_DTL_TYPE.LOAN });
    }
  }
};
</script>
