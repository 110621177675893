import { putCustTrxLog } from "@/api";
import { cleanObject, makeUnderScoreObject } from "@/helpers/index";
import store from "@/store";
import VM from "@/main";
import { CREATIVE_DTL_TYPE } from "@/const";

export const gaTracker = ({ category, action, label, value }) => {
  if (!VM) return console.error("VM IS NOT DEFINED");

  const { utm_campaign, utm_source, utm_medium, utm_content } = VM.$route.query;

  VM.$gtm.trackEvent(
    cleanObject({
      event: "ga-event",
      category,
      action,
      label,
      value,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_content
    })
  );
};

export const traceEvent = ({ eventType, gpMappSeq, adsSeq, subAdsSeq, payYn, cardSeq, loanSeq, promotionSeq, itSeq }) => {
  /* 필수값
   * trackCd, platformCd, payYn, eventType
   * */
  putCustTrxLog({
    condition: {
      trackCd: store.getters["user/trackCd"], // 유입된 사용자의 코드
      trackCdType: store.getters["user/trackCdType"], // 유입된 채널 경로에 대한 고유 코드
      platformCd: store.getters["user/osType"], // 안드로이드인지, IOS인지 여부
      eventType, // 해당 이벤트의 타입코드
      gpMappSeq: gpMappSeq && !isNaN(gpMappSeq) ? Number(gpMappSeq) : null, // 지면 매핑 번호 - 지면을 클릭하여 들어간 경우 파라미터로 따라가서 어떤 지면을 클릭했는지 추적할 수 있도록 해준다.
      adsSeq: adsSeq && !isNaN(adsSeq) ? Number(adsSeq) : null, // 소재 번호
      subAdsSeq: subAdsSeq && !isNaN(subAdsSeq) ? Number(subAdsSeq) : null, // 프로모션, 상세 등의 페이지에서 다른 연관된 상품을 클릭할 경우 해당 상품의 소재번호를 넘겨준다.
      payYn: payYn ? payYn : "N", // 클릭 등 액션에서 그룹 차감 방식에 따라 백엔드에서 Y이면 매출에서 차감, N이면 보너스에서 차감된다고 한다.(중요)
      creativeDtlSeq: cardSeq || loanSeq || promotionSeq || itSeq || null, // 프로모션, 상세 페이지를 띄울 경우 해당 페이지내에서 사용됨.
      creativeDtlType: cardSeq ? CREATIVE_DTL_TYPE.CARD : loanSeq ? CREATIVE_DTL_TYPE.LOAN : promotionSeq ? CREATIVE_DTL_TYPE.PROMOTION : itSeq ? CREATIVE_DTL_TYPE.IN_APP_TEMPLATE : null // null: backend와 싱크 맞춤
    }
  });
};

export const getTraceQuery = (obj = {}) => {
  /*
  os_type, gp_mapp_seq, pay_yn, ads_seq, from_push,
  track_cd, track_cd_type,
  prod_seq, promotion_seq, // NEW PAGE
  utm_campaign, utm_source, utm_medium, utm_content  // GA
  */

  const clonedObj = {
    track_cd: store.getters["user/trackCd"],
    track_cd_type: store.getters["user/trackCdType"],
    os_type: store.getters["user/osType"],
    ...obj
  };

  return makeUnderScoreObject(clonedObj);
};
