import axios from "axios";

export const trackingAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URI
});

export const defaultAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  timeout: 10000
});
