import Vue from "vue";
import Vuex from "vuex";
import modules from "@/store/modules";

export * from "@/store/modules/loan/loan";
export * from "@/store/modules/loan/promotion";
export * from "@/store/modules/user";
export * from "@/store/modules/ui";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: modules,
  state: {},
  getters: {},
  mutations: {},
  actions: {}
});
