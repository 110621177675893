const requireModule = require.context("./", true, /\.js$/);
const modules = {};

requireModule.keys().forEach((fileName) => {
  if (fileName === "./index.js") return;

  const moduleName = fileName.replace(/(\.\/|\.js)/gi, "");

  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default
  };
});

export default modules;
