<template>
  <v-dialog v-model="isBridgeDialogShow" width="500" persistent content-class="bridge-dialog">
    <v-card class="bridge-dialog-content text-center">
      <div class="loading-bar">
        <div style="width: 80px; height: 54px; margin: 0 auto" ref="lottieContainer"></div>
      </div>
      <p>금융사 페이지로 이동 후<br />신청을 진행해 주세요</p>
    </v-card>
  </v-dialog>
</template>

<script>
import { uiGetters, uiActions } from "@/store";
import lottie from "lottie-web";
const animationData = require("@/assets/lottie/loading.json");

export default {
  name: "BridgeDialog",
  data: () => ({
    anim: null
  }),
  computed: {
    ...uiGetters(["bridgeDialog", "isBridgeDialogShow"])
  },
  watch: {
    bridgeDialog() {
      if (this.bridgeDialog.isShow) this.initDialog();
    }
  },
  methods: {
    ...uiActions(["setBridgeDialog"]),

    initDialog() {
      this.$nextTick(() => {
        this.anim = lottie.loadAnimation({
          container: this.$refs.lottieContainer,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: animationData
        });
      });

      setTimeout(() => {
        if (this.bridgeDialog.callback) {
          this.bridgeDialog.callback();
        }

        this.setBridgeDialog({
          isShow: false,
          callback: null
        });

        this.anim.destroy();
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .bridge-dialog {
    margin: 15px;

    .bridge-dialog-content {
      padding-top: 16px;
      padding-bottom: 32px;

      p {
        font-size: rem(16px);
        margin-bottom: 0;
        line-height: 1.5;
        font-weight: 400;
      }
    }
  }
}
</style>
