<template>
  <li
    class="dialog-push-loan-item"
    :data-image-path="imgPath"
    :data-loan-name="loanName"
    :data-interest-min-rate="interestMinRate"
    :data-interest-max-rate="interestMaxRate"
    :data-credit-line-text="creditLineText"
    :data-support="support"
    @click="onClick"
  >
    <div class="img-wrap">
      <div class="img-outer">
        <img :src="imgPath" v-if="imgPath" :alt="`${loanName} 이미지`" />
      </div>
    </div>
    <div class="summary-info">
      <strong class="name">{{ loanName }} </strong>
      <div class="loan-desc-wrap">
        <dl class="loan-desc">
          <dt>금리(연)</dt>
          <dd>
            {{ interestMinRate }}<span v-if="interestMaxRate"> ~ {{ interestMaxRate }}%</span>
          </dd>
        </dl>
        <dl class="loan-desc">
          <dt>최대</dt>
          <dd>{{ creditLineText }}</dd>
        </dl>
        <dl class="loan-desc loan-support" v-if="support">
          <dt>대출이자</dt>
          <dd>
            <strong>{{ support }}% 지원</strong>
          </dd>
        </dl>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "DialogPushLoan",
  props: {
    imgPath: {
      type: String,
      default: ""
    },
    loanName: {
      type: String,
      default: ""
    },
    interestMinRate: {
      type: String,
      default: ""
    },
    interestMaxRate: {
      type: String,
      default: ""
    },
    creditLineText: {
      type: String,
      default: ""
    },
    support: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isView: false
    };
  },
  methods: {
    onClick(e) {
      this.$emit("click-item", e);
    }
  },
  mounted() {
    this.$nextTick(() => {
      //Todo 렌더링 시점 타이머 수정 필요
      setTimeout(() => {
        const el = this.$el;
        const position = el.getBoundingClientRect();

        this.isView = position.top < window.innerHeight;

        if (this.isView) {
          this.$emit("display-item", el);
        }
      }, 500);
    });
  }
};
</script>

<style scoped></style>
