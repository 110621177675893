import store from "@/store";
import { isIOS } from "mobile-device-detect";
import { cleanObject } from "@/helpers";

const AppCommand = Object.freeze({
  GET_APP_VERSION: "GET_APP_VERSION", // O
  REQUEST_PARTNER_TOKEN: "REQUEST_PARTNER_TOKEN", // O
  REQUEST_WEBVIEW_POPUP: "REQUEST_WEBVIEW_POPUP", // 잘 동작하나 작게 뜬다. 버전 무관하게 iOS에서 이상 동작이 있다. 사용하지 말 것.
  REQUEST_WEBVIEW: "REQUEST_WEBVIEW", // O
  //NAVIGATE_WINDOW: "NAVIGATE_WINDOW", //박철오/OCB개발팀 > 공식지원하는 JS규격이 아니라고 함. > 테스트 결과: iOS는 동작하지 않는다. AND만 잘 됨.
  REQUEST_URL: "REQUEST_URL",
  REQUEST_KMC_AUTH_WINDOW: "REQUEST_KMC_AUTH_WINDOW", // 띄워지긴 하나 휴대폰 인증이 안됨 > 사용하지 말 것.
  REQUEST_CAN_GO_BACK: "REQUEST_CAN_GO_BACK", // O
  REQUEST_GO_BACK: "REQUEST_GO_BACK",
  REQUEST_CLOSE_WINDOW: "REQUEST_CLOSE_WINDOW", // O
  REQUEST_CLOSE_WINDOW_WITH_REFERRING_URL: "REQUEST_CLOSE_WINDOW_WITH_REFERRING_URL",
  REQUEST_GO_HOME: "REQUEST_GO_HOME",
  ADD_TEXT_TO_CLIP_BOARD: "ADD_TEXT_TO_CLIP_BOARD", // O
  REGISTER_BACK_BUTTON_LISTENER: "REGISTER_BACK_BUTTON_LISTENER",
  REGISTER_BACK_KEY_LISTENER: "REGISTER_BACK_KEY_LISTENER", // O
  REGISTER_ON_RESUME: "REGISTER_ON_RESUME", // O
  REGISTER_ON_STOP: "REGISTER_ON_STOP",
  CLEAR_WV_HISTORY: "CLEAR_WV_HISTORY",
  SET_TITLE: "SET_TITLE",
  SET_BG_COLOR: "SET_BG_COLOR",
  START_BROWSER: "START_BROWSER", // O
  SHOW_POPUP: "SHOW_POPUP",
  TRACK_LOG: "TRACK_LOG" // O
});

class Bridge {
  constructor() {
    this.handler = isIOS ? new IOSHandler() : new AndroidHandler();
  }

  requestToApp(command, params) {
    console.log("requestToApp : isIOS - ", isIOS + ", COMMAND - " + command + ", PARAMS - " + JSON.stringify(params));
    this.handler.requestToApp(command, params);
  }
}

class Handler {
  constructor() {}

  checkAppVersion(targetVersion) {
    const appVersion = this.requestToApp(AppCommand.GET_APP_VERSION);
    if (appVersion) {
      const version = appVersion.name;
      if (version) {
        if (this.compareVersion(version, targetVersion)) {
          return true;
        } else {
          console.warn("The version is not satisfied, the legacy code is needed - current, target:", version, targetVersion);
          return false;
        }
      } else {
        console.error("The Get App version name is not defined");
        return false;
      }
    } else {
      console.error("The Get App version is failed");
      return false;
    }
  }

  compareVersion(version, compare) {
    const versionList = version.split("."); // 6.7
    const compareList = compare.split("."); // 6.7.3

    for (let i = 0; i < versionList.length && compareList.length; i++) {
      const version = versionList[i];
      if (Number(version) < Number(compareList[i])) {
        return false;
      } else if (Number(version) > Number(compareList[i])) {
        return true;
      }
    }

    let pass = true;
    if (compareList.length > versionList.length) {
      for (let compare of compareList) {
        if (Number(compare) > 0) {
          pass = false;
          break;
        }
      }
    }
    return pass;
  }

  // [ {onclick: Update.close, handler: () => {} }]
  makeWindowObject(actionList) {
    /*
    window.UpdateObject = {
      close() {
        console.log("closed");
      },
      update() {
        window.location.href = "https://itunes.apple.com/kr/app/ok-cashbag/id358731598?mt=8";
      }
    }; */

    let objectName;
    let object = {};
    for (let i = 0; i < actionList.length; i++) {
      if (actionList[i].handler) {
        const onclick = actionList[i].onclick;
        if (onclick) {
          const nameArr = onclick.split(".");
          if (nameArr.length === 2) {
            if (!objectName) {
              objectName = nameArr[0];
            } else {
              if (objectName !== nameArr[0]) {
                console.error("The object name must be same.");
              }
            }
            object[nameArr[1]] = actionList[i].handler;
          } else {
            console.error("The object name and the method name are not correct");
          }
        }
        delete actionList[i].handler;
      }
    }

    if (objectName) {
      if (window[objectName]) {
        delete window[objectName];
      }
      window[objectName] = object;
    } else {
      console.error("The objectName is not defined");
    }

    return actionList;
  }

  setTokenHandler(objectMethodName, query) {
    if (!objectMethodName) return console.error("The objectMethodName is not defined");

    this.makeWindowObject([
      {
        onclick: objectMethodName,
        handler: (result, token) => {
          //result : 0: 성공 , 2: 에러, 3: 접근 불가 (인가된 도메인 아님 ) 4: OCB 미가입
          if (result === 0) {
            console.log("setToken:" + token);
            store.dispatch("user/setToken", { token, query }, { root: true });
          } else if (result === 4) {
            this.requestToApp(AppCommand.REQUEST_URL, { url: "ocbt://com.skmc.okcashbag.home_google/auth/integration?type=CI" });

            // 위 인증이 실패 또는 취소하면 아래 resume handler를 타고 창이 닫힌다.
            // 성공하게 되면 APP에서 자체적으로 우리 페이지를 Refresh 시켜주고, 위의 result 0인 경우를 타게 되는데,
            // 앱에는 계속 등록된 상태가 되어서 화면이 resume될때마다 호출을 하게 된다. 그래서 result 0인 경우에도 동일하게 빈 것으로 하나 생성해준다.
            // iOS는 왜 인지는 모르겠으나 웹뷰의 refresh가 굼뜨다. > setTimeout을 안 넣으면 성공해도 뒤로 나가버린다. (최소 값은 1 second로 추정된다.)
            // 추측상으로는 iOS에서는 onResume을 먼저 호출하고 webview refresh를 해주는 것으로 보인다.
            this.requestToApp(AppCommand.REGISTER_ON_RESUME, {
              objectMethodName: "EbmpRegister.resume",
              handler: () => {
                setTimeout(() => {
                  this.requestToApp(AppCommand.REQUEST_CLOSE_WINDOW);
                }, 1000);
              }
            });
          } else {
            console.error("The token issue has an error:", result);

            store.dispatch(
              "ui/SET_GLOBAL_DIALOG",
              {
                isShow: true,
                message: "사용자 정보가 부정확합니다. result: " + result,
                isGotoAppMain: true
              },
              { root: true }
            );
          }
        }
      }
    ]);
  }

  setCanGoBackHandler(objectMethodName, handler) {
    if (!objectMethodName) return console.error("The objectMethodName is not defined");

    this.makeWindowObject([
      {
        onclick: objectMethodName,
        handler: handler
          ? handler
          : (enable) => {
              if (enable === 1) {
                this.requestToApp(AppCommand.REQUEST_GO_BACK);
              } else {
                this.requestToApp(AppCommand.REQUEST_CLOSE_WINDOW);
              }
            }
      }
    ]);
  }

  setBackKeyListener(objectMethodName, handler) {
    if (!objectMethodName) return console.error("The objectMethodName is not defined");

    this.makeWindowObject([
      {
        onclick: objectMethodName,
        handler: handler
          ? handler
          : () => {
              const length = window.history.length;
              if (length > 1) {
                this.requestToApp(AppCommand.REQUEST_GO_BACK);
              } else {
                this.requestToApp(AppCommand.REQUEST_CLOSE_WINDOW);
              }
            }
      }
    ]);
  }

  requestWebView(title, url) {
    // 갸이드 상으로는 아래 처럼 해달라고 하였음.
    // iOS도 동일하게 적혀 있다.
    // 팝업 웹뷰 : ocbt://com.skmc.okcashbag.home_google/viewPopup?url=https%3A%2F%2Fm.okcashbag.com
    // 타이틀 없는 전체 웹뷰 : ocbt://com.skmc.okcashbag.home_google/detail/eventNoTitle?url=https%3A%2F%2Fm.okcashbag.com&title=%EC%9D%B4%EB%B2%A4%ED%8A%B8
    // 타이틀 있는 경우 : ocbt://com.skmc.okcashbag.home_google/detail/event?url=https%3A%2F%2Fm.okcashbag.com&title=%EC%9D%B4%EB%B2%A4%ED%8A%B8

    // addSearchQuery
    let rootUrl = "ocbt://com.skmc.okcashbag.home_google/detail/";
    url = encodeURIComponent(url);
    if (title) {
      rootUrl += "event";

      console.log(`${rootUrl}?url=${url}&title=${encodeURIComponent(title)}`);
      location.href = `${rootUrl}?url=${url}&title=${encodeURIComponent(title)}`;
    } else {
      rootUrl += "eventNoTitle";

      console.log(`${rootUrl}?url=${url}`);
      location.href = `${rootUrl}?url=${url}`;
    }
  }
}

// IOS HANDLER
class IOSHandler extends Handler {
  constructor() {
    super();
    if (!window.OcbiOSJS) return console.error("The OcbiOSJS is not defined");
    if (!window.OcbiOSTitleViewJS) return console.error("The OcbTitleViewJS is not defined");
  }

  requestAppUpdate() {
    // 앱 업데이트 유도
    this.requestToApp(AppCommand.SHOW_POPUP, {
      popup: {
        title: "",
        message: "서비스를 이용하기 위해 앱을 최신으로 업데이트 해주세요.",
        action: [
          {
            0: "업데이트",
            onclick: "UpdateObject.update",
            handler: () => {
              window.location.href = "https://itunes.apple.com/kr/app/ok-cashbag/id358731598?mt=8";
            }
          },
          { 1: "닫기", onclick: "UpdateObject.close", handler: () => {} }
        ]
      }
    });
  }

  requestToApp(
    command,
    { log, text, objectMethodName, handler, url, title, delay, popup, bgColor, query } = {
      log: { page_id: "", action_id: "", common_code: "", category_name: "", display_order: "" },
      popup: { title: "", message: "", action: [] }
    }
  ) {
    if (!window.OcbiOSJS) return;
    if (!window.OcbiOSTitleViewJS) return;

    switch (command) {
      case AppCommand.REQUEST_GO_HOME: {
        location.href = "ocbt://com.skmc.okcashbag.home_google/main";
        break;
      }
      case AppCommand.TRACK_LOG: {
        if (!log) return console.error("log is not defined");

        window.OcbiOSJS.trackLog(JSON.stringify(cleanObject(log)));
        window.OcbiOSJS.trackFlush();
        break;
      }
      case AppCommand.GET_APP_VERSION: {
        try {
          // above "6.0.0"
          return JSON.parse(window.OcbiOSJS.getAppVersion()); // {"name":"5.0.0", "code":60}'
        } catch (e) {
          this.requestAppUpdate();
          return;
        }
      }
      case AppCommand.REQUEST_PARTNER_TOKEN: {
        if (this.checkAppVersion("6.4.5")) {
          this.setTokenHandler(objectMethodName, query);

          window.OcbiOSJS.requestPartnerToken(process.env.VUE_APP_SERVICE_ID, objectMethodName);
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REQUEST_KMC_AUTH_WINDOW: {
        this.makeWindowObject([
          {
            onclick: objectMethodName,
            handler
          }
        ]);

        window.OcbiOSJS.requestKmcAuthWindow(objectMethodName);
        break;
      }
      case AppCommand.ADD_TEXT_TO_CLIP_BOARD: {
        window.OcbiOSJS.addTextToClipboard(text);
        break;
      }
      case AppCommand.REQUEST_URL: {
        // 약속된 url scheme을 넘겨야 한다.
        location.href = url;
        break;
      }
      case AppCommand.REQUEST_WEBVIEW: {
        if (store.getters["ui/webViewTriggered"]) return console.log("The webview already triggered");

        store.dispatch("ui/setWebviewTriggered", true, { root: true });

        // 다른 곳에서 등록한 ON_RESUME이 일어나지 않도록 RESET해준다.
        this.requestToApp(AppCommand.REGISTER_ON_RESUME, {
          objectMethodName: "EbmpRegister.resume",
          handler: () => {}
        });

        this.requestWebView(title, url);

        setTimeout(() => {
          store.dispatch("ui/setWebviewTriggered", false, { root: true });
        }, 800);
        break;
      }
      case AppCommand.REQUEST_WEBVIEW_POPUP: {
        if (this.checkAppVersion("5.8.0")) {
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbiOSJS.requestWebviewPopup(url, objectMethodName);
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REGISTER_BACK_BUTTON_LISTENER: {
        if (this.checkAppVersion("5.7.0")) {
          /*Register.onBackButtonListener = function() {
            // back button clicked
          }*/

          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbiOSTitleViewJS.registerBackButtonListener(objectMethodName); // 'Register.onBackButtonListener'
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REQUEST_CAN_GO_BACK: {
        if (this.checkAppVersion("6.4.5")) {
          this.setCanGoBackHandler(objectMethodName, handler);

          window.OcbiOSJS.requestCanGoBack(objectMethodName); // 'Register.onHandleBackButton'
        } else {
          this.requestAppUpdate();
        }

        break;
      }
      case AppCommand.REQUEST_GO_BACK: {
        window.OcbiOSJS.requestGoBack();
        break;
      }
      case AppCommand.REQUEST_CLOSE_WINDOW: {
        window.OcbiOSJS.requestCloseWindow();
        break;
      }
      case AppCommand.REQUEST_CLOSE_WINDOW_WITH_REFERRING_URL: {
        /*
          iOS에서 화면 종료 후 앱 내 다른 화면으로 이동하고자 하는 경우
          requestCloseWindowWithReferringUrl(url) 을 사용하도록 한다.
          해당 API 는 6.7.0 이상 부터 정식 지원하고 있으므로, 이하 버전은 앱 업데이트를 유도하거나,
          화면 이동이 필요한 경우 화면 종료 처리는 하지 않도록 한다.
        */
        if (this.checkAppVersion("6.7.0")) {
          window.OcbiOSJS.requestCloseWindowWithReferringUrl(url);
        } else {
          window.document.location.href = url;
          setTimeout(() => {
            this.requestToApp(AppCommand.REQUEST_CLOSE_WINDOW);
          }, delay);
        }
        break;
      }
      case AppCommand.REGISTER_BACK_KEY_LISTENER: {
        // iOS do not work.
        // hardware back button listener
        this.setBackKeyListener(objectMethodName, handler);

        window.OcbiOSJS.registerBackKeyListener(objectMethodName); // 'Register.onBackKeyListener'
        break;
      }
      case AppCommand.REGISTER_ON_RESUME: {
        if (this.checkAppVersion("5.9.0")) {
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbiOSJS.registerOnResumeCallback(objectMethodName); // 'LifeCycle.onResume'
        } else {
          this.requestAppUpdate();
        }

        break;
      }
      case AppCommand.REGISTER_ON_STOP: {
        if (this.checkAppVersion("5.9.0")) {
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbiOSJS.registerOnStopCallback(objectMethodName); // 'LifeCycle.onStop'
        } else {
          this.requestAppUpdate();
        }

        break;
      }
      case AppCommand.CLEAR_WV_HISTORY: {
        window.OcbiOSJS.clearWebViewHistory();
        break;
      }
      case AppCommand.START_BROWSER: {
        if (this.checkAppVersion("6.0")) {
          // the method name is not matched with the Android's
          window.OcbiOSJS.startBrowserWithURLEncoding(url, false);
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.SHOW_POPUP: {
        popup.action = this.makeWindowObject(popup.action);

        /*{
          title: "",
            message: "오사라마켓 사용을 위해 앱을 최신 업데이트 해주세요.",
          action: [
          { "0": "업데이트", onclick: "UpdateObject.update" },
          { "1": "닫기", onclick: "UpdateObject.close" }
        ]
        }*/
        window.OcbiOSJS.showPopup(JSON.stringify(popup));
        break;
      }
      case AppCommand.SET_TITLE: {
        window.OcbiOSTitleViewJS.setTitle(title);
        break;
      }
      case AppCommand.SET_BG_COLOR: {
        window.OcbiOSTitleViewJS.setBgColor(bgColor); // #FF0000
        break;
      }
    }
  }
}

// ANDROID HANDLER
class AndroidHandler extends Handler {
  constructor() {
    super();
    if (!window.OcbAndroidJS) return console.error("The OcbAndroidJS is not defined");
    if (!window.OcbTitleViewJS) return console.error("The OcbTitleViewJS is not defined");
  }

  requestAppUpdate() {
    // 앱 업데이트 유도
    this.requestToApp(AppCommand.SHOW_POPUP, {
      popup: {
        title: "",
        message: "서비스를 이용하기 위해 앱을 최신으로 업데이트 해주세요.",
        action: [
          {
            0: "업데이트",
            onclick: "UpdateObject.update",
            handler: () => {
              window.location.href = "market://details?id=com.skmc.okcashbag.home_google";
            }
          },
          { 1: "닫기", onclick: "UpdateObject.close", handler: () => {} }
        ]
      }
    });
  }

  requestToApp(
    command,
    { log, text, objectMethodName, handler, url, popup, title, bgColor, query } = {
      log: { page_id: "", action_id: "", common_code: "", category_name: "", display_order: "" },
      popup: { title: "", message: "", action: [] }
    }
  ) {
    if (!window.OcbAndroidJS) return;
    if (!window.OcbTitleViewJS) return;

    switch (command) {
      case AppCommand.REQUEST_GO_HOME: {
        location.href = "ocbt://com.skmc.okcashbag.home_google/main";
        break;
      }
      case AppCommand.TRACK_LOG: {
        if (!log) return console.error("log is not defined");

        window.OcbAndroidJS.trackLog(JSON.stringify(cleanObject(log)));
        window.OcbAndroidJS.trackFlush();
        break;
      }
      case AppCommand.GET_APP_VERSION: {
        try {
          // above "5.0", the version is not matched with iOS
          return JSON.parse(window.OcbAndroidJS.getAppVersion()); // {"name":"5.0.0", "code":60}'
        } catch (e) {
          this.requestAppUpdate();
          return;
        }
      }
      case AppCommand.REQUEST_PARTNER_TOKEN: {
        if (this.checkAppVersion("6.4.5")) {
          this.setTokenHandler(objectMethodName, query);

          window.OcbAndroidJS.requestPartnerToken(process.env.VUE_APP_SERVICE_ID, objectMethodName); // "Partner.onToken"
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REQUEST_KMC_AUTH_WINDOW: {
        this.makeWindowObject([
          {
            onclick: objectMethodName,
            handler
          }
        ]);

        window.OcbAndroidJS.requestKmcAuthWindow(objectMethodName);
        break;
      }
      case AppCommand.ADD_TEXT_TO_CLIP_BOARD: {
        window.OcbAndroidJS.addTextToClipboard(text);
        break;
      }
      case AppCommand.REQUEST_URL: {
        // 약속된 url scheme을 넘겨야 한다.
        location.href = url;
        break;
      }
      case AppCommand.REQUEST_WEBVIEW: {
        if (store.getters["ui/webViewTriggered"]) return console.log("The webview already triggered");

        store.dispatch("ui/setWebviewTriggered", true, { root: true });

        this.requestToApp(AppCommand.REGISTER_ON_RESUME, {
          objectMethodName: "EbmpRegister.resume",
          handler: () => {}
        });

        this.requestWebView(title, url);

        setTimeout(() => {
          store.dispatch("ui/setWebviewTriggered", false, { root: true });
        }, 800);
        break;
      }
      case AppCommand.REQUEST_WEBVIEW_POPUP: {
        if (this.checkAppVersion("5.8.0")) {
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbAndroidJS.requestWebviewPopup(url, objectMethodName);
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REGISTER_BACK_BUTTON_LISTENER: {
        if (this.checkAppVersion("5.7.0")) {
          // the version is not matched with iOS
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbTitleViewJS.registerBackButtonListener(objectMethodName);
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REQUEST_CAN_GO_BACK: {
        if (this.checkAppVersion("6.4.5")) {
          this.setCanGoBackHandler(objectMethodName, handler);

          window.OcbAndroidJS.requestCanGoBack(objectMethodName);
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REQUEST_GO_BACK: {
        window.OcbAndroidJS.requestGoBack();
        break;
      }
      case AppCommand.REQUEST_CLOSE_WINDOW: {
        window.OcbAndroidJS.requestCloseWindow();
        break;
      }
      case AppCommand.REQUEST_CLOSE_WINDOW_WITH_REFERRING_URL: {
        window.document.location.href = url;
        this.requestToApp(AppCommand.REQUEST_CLOSE_WINDOW);
        break;
      }
      case AppCommand.REGISTER_BACK_KEY_LISTENER: {
        // hardware back button listener
        this.setBackKeyListener(objectMethodName, handler);

        window.OcbAndroidJS.registerBackKeyListener(objectMethodName); // 'Register.onBackKeyListener'
        break;
      }
      case AppCommand.REGISTER_ON_RESUME: {
        if (this.checkAppVersion("5.8.8")) {
          // the version is not matched with iOS
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbAndroidJS.registerOnResumeCallback(objectMethodName); // 'LifeCycle.onResume'
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.REGISTER_ON_STOP: {
        if (this.checkAppVersion("5.8.8")) {
          // the version is not matched with iOS
          this.makeWindowObject([
            {
              onclick: objectMethodName,
              handler
            }
          ]);

          window.OcbAndroidJS.registerOnStopCallback(objectMethodName); // 'LifeCycle.onStop'
        } else {
          this.requestAppUpdate();
        }
        break;
      }
      case AppCommand.CLEAR_WV_HISTORY: {
        window.OcbAndroidJS.clearWebViewHistory();
        break;
      }
      case AppCommand.START_BROWSER: {
        if (this.checkAppVersion("6.0")) {
          window.OcbAndroidJS.startBrowser(url, null);
        } else {
          if (this.checkAppVersion("5.0")) {
            window.OcbAndroidJS.startBrowser(url, null);
          } else {
            this.requestAppUpdate();
          }
        }
        break;
      }
      case AppCommand.SHOW_POPUP: {
        if (this.checkAppVersion("5.0")) {
          // the version is not matched with iOS
          popup.action = this.makeWindowObject(popup.action);

          /*{
            title: "",
              message: "오사라마켓 사용을 위해 앱을 최신 업데이트 해주세요.",
            action: [
            { "0": "업데이트", onclick: "UpdateObject.update" },
            { "1": "닫기", onclick: "UpdateObject.close" }
          ]
          }*/
          window.OcbAndroidJS.showPopup(JSON.stringify(popup));
        } else {
          this.requestAppUpdate();
        }

        break;
      }
      case AppCommand.SET_TITLE: {
        window.OcbTitleViewJS.setTitle(title);
        break;
      }
      case AppCommand.SET_BG_COLOR: {
        window.OcbTitleViewJS.setBgColor(bgColor); // #FF0000
        break;
      }
    }
  }
}

export { AppCommand, Bridge };
