import VM from "@/main";
import { AppCommand } from "@/api/bridge";
import { IS_LOCAL_ENV, VALID_TEST_TOKEN } from "@/const";
import store from "@/store";

/*
E0001 : 미성년자
-----------------------------------
40130 : 토큰 1회 리트라이 (OCB의 코드 - 만료된 토큰 등 )
E0002 : 백엔드가 토큰 못받을 때 (EBMP API의 코드)
-----------------------------------
E0003 : OCB 에러코드 없는데, 데이터 비정상
50000 : API 오류
----------------------------------
그 외 코드는 OCB에러코드 알 수 없음
*/
let hasRequestedToken = false;

export const showFailMessage = (commit, { resultCode, resultMessage }) => {
  const MESSAGE_DICT = {
    E0001: "미성년자는 사용할 수 없습니다.",
    E0002: "사용자 정보가 잘못 되었습니다.",
    40130: "사용자 정보가 잘못 되었습니다."
  };

  commit(
    "ui/SET_GLOBAL_DIALOG",
    {
      isShow: true,
      message: MESSAGE_DICT[resultCode] || resultMessage || "잠시 후 다시 시도 해주세요.",
      isGotoAppMain: !["40130", "E0002"].includes(resultCode) ? true : hasRequestedToken // token 잘못된 경우가 아니라면 확인시 메인으로 간다. 만약 token이 잘못된 경우라도 token을 1번 이미 보낸 경우라면 역시 메인으로 간다.
    },
    { root: true }
  );

  setTimeout(() => {
    switch (resultCode) {
      case "40130":
      case "E0002": {
        // 토큰 만료로 재로딩해야 하나, 화면 새로고침시 token 발급 후 처리도 하기 때문에 더 안전한 선택으로 보인다.
        if (!hasRequestedToken) {
          console.log("token request retry triggered!");

          if (IS_LOCAL_ENV) {
            // 테스트용 토큰 : T46f1823d9138493e
            store.dispatch("user/setToken", { token: VALID_TEST_TOKEN, query: { ...VM.$route.query } }, { root: true });
          } else {
            VM.$bridge.requestToApp(AppCommand.REQUEST_PARTNER_TOKEN, {
              objectMethodName: "EbmpPartner.onToken",
              query: { ...VM.$route.query }
            });
          }

          hasRequestedToken = true;

          commit(
            "ui/SET_GLOBAL_DIALOG",
            {
              isShow: false,
              message: ""
            },
            { root: true }
          );
        }
        break;
      }
    }
  }, 1000);
};
