<template>
  <v-snackbar v-model="globalSnackbar.isShow" :timeout="globalSnackbar.timeout">
    {{ globalSnackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="#fff" text v-bind="attrs" @click="setGlobalSnackbar({ isShow: false })">
        닫기
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { uiGetters } from "@/store";

export default {
  name: "GlobalSnackbar",
  computed: {
    ...uiGetters(["globalSnackbar", "isGlobalSnackbarShow"])
  }
};
</script>

<style lang="scss" scoped></style>
