import { defaultAxios, trackingAxios } from "./base";
import { IS_LOCAL_ENV } from "@/const";

/*
고객정보 처리동의 팝업은 쿠키로 처리
필수값 없을 경우 계속 isNeedAgree = true 로 리턴
*/

/*
OCB쪽 API 호출 후 에러가 있는 경우 리턴값
OCB_MINORS("E0001", "ocb.err.minors"),
OCB_TOKEN_VALID("E0002", "ocb.err.not.valid.token"),
OCB_CUST_INFO_API("E0003", "ocb.err.cust.info.api");

MINORS(E0001) -> 미성년자
E0002 -> 요청값에 토큰값 없을 시
E0003 -> 응답값 오류케이스 전체
(추후 세분화가 필요할 경우는 추가될 수 있습니다.)
E0003 케이스는 API통신중 오류, 응답 필수값 미존재(mbrId, birthDate)
*/

class FakeApi {
  constructor() {
    console.log("FakeApi initialized");
    this.data = {
      /*"/cmm/codeList": {
        isSucceed: true,
        resultCode: "S0000",
        resultMessage: null,
        durationTime: 0.019780102,
        trackCd: "FE6D28F7221249E18AEB9A175C8E7718",
        result: [
          {
            codeGrp: "C007",
            list: [
              {
                regDate: "",
                regUserSeq: "",
                updDate: "",
                updUserSeq: "",
                codeGrp: "C007",
                code: "C",
                codeNm: "신용",
                description: "신용",
                dispOrd: "1",
                useYn: "Y",
                property: "",
                parentCode: "",
                codeDepth: "1",
                abbreviation: "01",
                codeNmAbbr: "신용"
              },
              {
                regDate: "",
                regUserSeq: "",
                updDate: "",
                updUserSeq: "",
                codeGrp: "C007",
                code: "A",
                codeNm: "대환",
                description: "대환",
                dispOrd: "2",
                useYn: "Y",
                property: "",
                parentCode: "",
                codeDepth: "1",
                abbreviation: "02",
                codeNmAbbr: "대환"
              },
              {
                regDate: "",
                regUserSeq: "",
                updDate: "",
                updUserSeq: "",
                codeGrp: "C007",
                code: "M",
                codeNm: "정부지원",
                description: "정부지원",
                dispOrd: "3",
                useYn: "Y",
                property: "",
                parentCode: "",
                codeDepth: "1",
                abbreviation: "03",
                codeNmAbbr: "정부지원"
              },
              {
                regDate: "",
                regUserSeq: "",
                updDate: "",
                updUserSeq: "",
                codeGrp: "C007",
                code: "I",
                codeNm: "사업자",
                description: "사업자",
                dispOrd: "4",
                useYn: "Y",
                property: "TEXT",
                parentCode: "",
                codeDepth: "1",
                abbreviation: "04",
                codeNmAbbr: "사업자"
              },
              {
                regDate: "",
                regUserSeq: "",
                updDate: "",
                updUserSeq: "",
                codeGrp: "C007",
                code: "P",
                codeNm: "자동차",
                description: "자동차",
                dispOrd: "5",
                useYn: "Y",
                property: "TEXT",
                parentCode: "",
                codeDepth: "1",
                abbreviation: "05",
                codeNmAbbr: "자동차"
              },
              {
                regDate: "",
                regUserSeq: "",
                updDate: "",
                updUserSeq: "",
                codeGrp: "C007",
                code: "B",
                codeNm: "부동산",
                description: "부동산",
                dispOrd: "8",
                useYn: "Y",
                property: "TEXT",
                parentCode: "",
                codeDepth: "1",
                abbreviation: "08",
                codeNmAbbr: "부동산"
              }
            ]
          }
        ]
      },
      "/ebm/external/banner": {
        isSucceed: true,
        resultCode: "S0000",
        resultMessage: null,
        durationTime: null,
        trackCd: null,
        result: {
          list: [
            {
              destinationType: "P",
              destinationUrl: "",
              imgPath: "https://devcontents.ebmp.kr/202201/2022012713350724.jpg",
              name: "웰컴 중금리대출",
              seq: "",
              compNm: "웰컴저축은행",
              promotionSeq: "173",
              itSeq: null,
              desc1: null,
              desc2: null,
              adsSeq: 1935,
              isRecommend: "N",
              iosSlot: 1,
              andSlot: 1,
              support: 0,
              cashback: 0
            }
          ]
        }
      },
      "/ebm/external/loanBenefitList": {
        isSucceed: true,
        resultCode: "S0000",
        resultMessage: null,
        durationTime: null,
        trackCd: null,
        result: {
          list: [
            {
              gpMappSeq: 2201,
              adsDtlSeq: 1135,
              adsSeq: 1935,
              payYn: "N",
              compNm: "웰컴저축은행",
              loanName: "웰컴 중금리대출",
              itSeq: null,
              destinationType: "P",
              destinationUrl: "",
              imgPath: "https://devcontents.ebmp.kr/202201/2022012713350724.jpg",
              promotionSeq: "205",
              licenseNo1: "웰컴저축은행 준법감시인 심의필 제1699호(21.12.23 ~ 22.12.22)",
              licenseNo2: "",
              interestMaxRate: "16.0",
              interestMinRate: "5.9",
              creditLineText: "1억원",
              support: "0",
              advtTypeCd: "20",
              advtText: "웰컴 TEST",
              advtColor: "#0A65EE"
            },
            {
              gpMappSeq: 2202,
              adsDtlSeq: 1136,
              adsSeq: 1936,
              payYn: "N",
              compNm: "NH농협캐피탈",
              loanName: "NH농협캐피탈 물론 다이렉트론",
              itSeq: null,
              destinationType: "C",
              destinationUrl: "https://m.nhcapital.co.kr/landing/mo/m_nhcapital_landing20.nh?INTFLDCHN=27&INTFLDTYP=02&utm_source=pass&utm_medium=dn&utm_campaign=%ED%95%98%EB%8B%A8%EB%B0%B0%EB%84%88",
              imgPath: "",
              promotionSeq: "",
              licenseNo1: "준법심의필-2112-17(2021.12.29.)",
              licenseNo2: "",
              interestMaxRate: "19.9",
              interestMinRate: "4.9",
              creditLineText: "1억 5,000만원",
              support: "0",
              advtTypeCd: "20",
              advtText: "NH농협캐피탈 대출",
              advtColor: "#9E23FF"
            }
          ]
        }
      },
      "/ebm/external/loanTopSupport": {
        isSucceed: true,
        resultCode: "S0000",
        resultMessage: null,
        durationTime: null,
        trackCd: null,
        result: {
          list: [
            {
              logoImg: "https://devcontents.ebmp.kr/202104/2021042118305617.png",
              compNm: "웰컴저축은행",
              compSeq: "34",
              promotionSeq: "205",
              dailyRemain: 999992,
              dailyBonusRemain: 0,
              priority: "S",
              payYn: "Y",
              advtTypeCd: null,
              advtText: null,
              advtColor: null,
              support: null,
              cashback: null,
              supportText: "3"
            },
            {
              logoImg: "https://devcontents.ebmp.kr/202105/2021051215413927.png",
              compNm: "SBI저축은행",
              compSeq: "104",
              promotionSeq: "210",
              dailyRemain: 29999994,
              dailyBonusRemain: 0,
              priority: "S",
              payYn: "Y",
              advtTypeCd: null,
              advtText: null,
              advtColor: null,
              support: null,
              cashback: null,
              supportText: "2"
            },
            {
              logoImg: "https://devcontents.ebmp.kr/202105/2021051016001120.png",
              compNm: "페퍼저축은행",
              compSeq: "7",
              promotionSeq: "214",
              dailyRemain: 999997,
              dailyBonusRemain: 0,
              priority: "S",
              payYn: "Y",
              advtTypeCd: null,
              advtText: null,
              advtColor: null,
              support: null,
              cashback: null,
              supportText: "2"
            },
            {
              logoImg: "https://devcontents.ebmp.kr/202104/2021040813275527.png",
              compNm: "삼성카드",
              compSeq: "25",
              promotionSeq: "209",
              dailyRemain: 10000000,
              dailyBonusRemain: 0,
              priority: "S",
              payYn: "Y",
              advtTypeCd: null,
              advtText: null,
              advtColor: null,
              support: null,
              cashback: null,
              supportText: "1"
            },
            {
              logoImg: "https://devcontents.ebmp.kr/202105/2021051716563074.png",
              compNm: "신한저축은행",
              compSeq: "107",
              promotionSeq: "211",
              dailyRemain: 4999997,
              dailyBonusRemain: 0,
              priority: "S",
              payYn: "Y",
              advtTypeCd: null,
              advtText: null,
              advtColor: null,
              support: null,
              cashback: null,
              supportText: "1"
            },
            {
              logoImg: "https://devcontents.ebmp.kr/202104/2021043010464183.png",
              compNm: "유진저축은행",
              compSeq: "16",
              promotionSeq: "224",
              dailyRemain: 1000000,
              dailyBonusRemain: 0,
              priority: "S",
              payYn: "Y",
              advtTypeCd: null,
              advtText: null,
              advtColor: null,
              support: null,
              cashback: null,
              supportText: "1"
            }
          ]
        }
      },
      /!*"/ebm/loan/promotion": {},*!/
      "/ebm/ocb/putAgreeInfo": {},
      "/ebm/ocb/user/info": {
        isSucceed: true,
        resultCode: "S0000",
        resultMessage: null,
        durationTime: 0.210981646,
        trackCd: "FE6D28F7221249E18AEB9A175C8E7718",
        result: {
          custNm: null,
          custSeq: null,
          isNeedAgree: true,
          userInfo: {
            custSeq: null,
            ctn: "thh1U4Xth28O+zcmp/8yjA==",
            mgntCd: "/EilFobONzOEU4AR4NXZmw==",
            custNm: "bk61aVz1ZUI2De7gPL9HFw==",
            birthDt: "9lNB7VNUlbSoQTNbV966xg==",
            gender: "RZbwUl4r7peVzdUl8N2fPg==",
            osType: null
          },
          agreeInfo: {
            infoUse: null,
            infoUseDate: null,
            mktRecv: null,
            mktRecvDate: null,
            trdInfo: null,
            trdInfoDate: null,
            pushRecv: null,
            pushRecvDate: null
          },
          placementList: null
        }
      },
      "/ebm/putCustTrxLog": {}*/
    };
  }

  getter(url, params, type) {
    console.log("url:", url, ", params:", params);
    if (this.data[url]) {
      return { data: this.data[url] };
    } else {
      return defaultAxios[type](url, params);
    }
  }

  post(url, params) {
    return this.getter(url, params, "post");
  }

  put(url, params) {
    return this.getter(url, params, "put");
  }
}

const axios = IS_LOCAL_ENV ? new FakeApi() : defaultAxios;

/* 공통 코드 리스트 */
export const getCodeList = (params) => {
  return axios.post(`/cmm/codeList`, params);
};

// trackCd, condition - osType, mediaSeq, placeSeq, adCateCd
/* 대출 상단 배너 */
export const getBanner = (params) => {
  return axios.post(`/ebm/external/banner`, params);
};

/* 대출 혜택 카테고리 */
// 사업팀 요청 - https://finbridgeworkspace.atlassian.net/browse/EBM-1080?atlOrigin=eyJpIjoiZmU5NzVlZjk5YzY2NDZiYTljMTlmMzkxODcxYzJiMzMiLCJwIjoiaiJ9
export const getCategoryList = (params) => {
  return axios.post(`/ebm/external/categoryList`, params);
};

/* 대출 혜택 모아보기 */
export const getLoanBenefitList = (params) => {
  return axios.post(`/ebm/external/loanBenefitList`, params);
};

/* 금융사별 상품 모아보기 */
export const getLoanTopSupport = (params) => {
  return axios.post(`/ebm/external/loanTopSupport`, params);
};

/* 대출 프로모션 상세 */
export const getLoanPromotion = (params) => {
  return axios.post(`/ebm/loan/promotion`, params);
};

/* 동의여부 저장 */
export const putAgreeInfo = (params) => {
  return axios.put(`/ebm/ocb/putAgreeInfo`, params);
};

/* OCB 사용자 정보 조회 */
export const getOcbUserInfo = (params) => {
  return axios.post(`/ebm/ocb/user/info`, params);
};

/* 사용자거래로그 */
export const putCustTrxLog = (params) => {
  return trackingAxios.put(`/ebm/putCustTrxLog`, params);
};

/* 템플릿 상세 */
export const getInAppTemplate = (params) => {
  return axios.post(`/ebm/creative/template`, params);
};
