import Vue from "vue";
import VueRouter from "vue-router";
import { ROUTES_NAME } from "@/const/router";
import { cleanObject } from "@/helpers";
import { AppCommand } from "@/api/bridge";
import store from "@/store";
import { IS_DEV_ENV, IS_LOCAL_ENV, TEST_TOKEN } from "@/const";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const routes = [
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/404",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "@/views/error/PageNotFound.vue")
  },
  {
    path: "/maintenance",
    name: ROUTES_NAME.MAINTENANCE,
    component: () => import(/* webpackChunkName: "Maintenance" */ "@/views/Maintenance")
  },
  {
    path: `/loan`,
    name: ROUTES_NAME.LOAN,
    meta: {
      screenName: "대출 메인",
      authRequired: true
    },
    props: (route) => ({ query: route.query }),
    component: () => import(/* webpackChunkName: "Loan" */ "@/views/loan")
  },
  {
    path: `/loan/promotion/:promotionSeq`,
    name: ROUTES_NAME.LOAN_PROMOTION,
    meta: {
      screenName: "대출 프로모션 상세",
      authRequired: true
    },
    props: (route) => ({ query: route.query }),
    component: () => import(/* webpackChunkName: "LoanPromotion" */ "@/views/loan/promotion")
  },
  {
    path: `/loan/template/:itSeq`,
    name: ROUTES_NAME.LOAN_TEMPLATE,
    meta: {
      screenName: "대출 브릿지",
      authRequired: true
    },
    props: (route) => ({ query: route.query }),
    component: () => import(/* webpackChunkName: "LoanTemplate" */ "@/views/loan/template")
  },
  {
    path: `/terms`,
    name: ROUTES_NAME.TERMS,
    meta: { screenName: "개인정보 수집/이용 동의" },
    component: () => {
      return import(/* webpackChunkName: "Terms" */ "@/views/terms/index");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.afterEach(async (to) => {
  if (to.meta) {
    if (to.meta.screenName) {
      to.meta.gtm = `[OCB] ${to.meta.screenName}`;
    }
  }

  const { utm_campaign, utm_medium, utm_source, utm_content } = to.query;
  if (utm_campaign || utm_medium || utm_source || utm_content) {
    to.meta.gtmAdditionalEventData = cleanObject({
      utm_campaign,
      utm_medium,
      utm_source,
      utm_content
    });
  }
});

router.beforeEach(async (to, from, next) => {
  const isUnderMaintenance = false;
  if (isUnderMaintenance && to.name !== ROUTES_NAME.MAINTENANCE) {
    next({ name: ROUTES_NAME.MAINTENANCE });
  } else {
    // token 체크 : 이전에 한번 호출했었는지 여부를 확인하는 용도
    // 호출했으면 사용자 정보를 가져왔을 것임. tracking을 유지하려면 trackCd는 지속적으로 동일해야 한다.(새창에서 query에 실어야 함)
    const token = store.getters["users/token"];
    if (!token) {
      if (IS_LOCAL_ENV && !to.query.cust_seq) {
        // 로컬에서 토큰으로 테스트 하고 싶을 때
        store.dispatch("user/setToken", { token: TEST_TOKEN, query: { ...to.query } }, { root: true });
      } else if (IS_DEV_ENV && to.query.cust_seq) {
        // 스테이징 등에서 토큰 발급 안 받고, cust_seq로 미리보기
        store.dispatch("user/setToken", { token: TEST_TOKEN, query: { ...to.query } }, { root: true });
      } else {
        // main.js에서 현재 파일을 쓰기 때문에 main.js를 import하여 사용하지 않았다.
        Vue.prototype.$bridge.requestToApp(AppCommand.REQUEST_PARTNER_TOKEN, { objectMethodName: "EbmpPartner.onToken", query: { ...to.query } });
        Vue.prototype.$bridge.requestToApp(AppCommand.REGISTER_BACK_KEY_LISTENER, { objectMethodName: "EbmpBackKey.back" });
      }
    }
    next();
  }
});

export default router;
