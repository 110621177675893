import { createNamespacedHelpers } from "vuex";
import { showFailMessage } from "@/api/handler.js";
import { getRollTime } from "@/helpers";
import { IS_DEV_ENV, OS_TYPE, WALK_IN_TRACK_CD_TYPE } from "@/const";
import VM from "@/main";
import { isIOS } from "mobile-device-detect";
import { getOcbUserInfo, putAgreeInfo } from "@/api";

export default {
  state: {
    token: "",
    custNm: "",
    custSeq: null, // BACKEND : LONG TYPE
    trackCd: "",
    trackCdType: "",
    userInfo: {},
    pushInfo: {},
    agreeInfo: {},
    isNeedAgree: null,
    placementList: []
  },
  getters: {
    token(state) {
      return state.token;
    },
    trackCd(state) {
      // BackEnd에서 요청 : custSeq가 없으면 null로 반환
      // 현재 우리쪽 사용자가 아니기 때문임. > 새로 발급받아야 한다.
      if (!state.custSeq) return null;

      return state.trackCd;
    },
    trackCdType(state) {
      return state.trackCdType;
    },
    osType() {
      // 화면보기(CMS)
      // userInfo의 osType은 null로 항시 들어오므로 쓸 수 없는 값임.
      const { os_type } = VM.$route.query;
      if (os_type) return os_type;

      return isIOS ? OS_TYPE.IOS : OS_TYPE.AND;
    },
    custNm(state) {
      return state.custNm;
    },
    custSeq(state) {
      return state.custSeq;
    },
    userInfo(state) {
      return state.userInfo;
    },
    pushInfo(state) {
      return state.pushInfo;
    },
    productType(state) {
      return state.pushInfo.productType;
    },
    agreeInfo(state) {
      return state.agreeInfo;
    },
    isInitialUser(state) {
      if (!state.agreeInfo) return false;

      let initial = true;
      [...Object.values(state.agreeInfo)].forEach((v) => {
        if (v !== null) {
          initial = false;
        }
      });
      return initial;
    },
    agreeInfoForm(state, getters) {
      return {
        trdInfo: getters.isTrdInfo
      };
    },
    isTrdInfo(state) {
      return state.agreeInfo && state.agreeInfo.trdInfo && state.agreeInfo.trdInfo === "Y";
    },
    isPushList(state) {
      const { list } = state.pushInfo;

      return !!(list && list.length > 0);
    },
    isNeedAgree(state) {
      return state.isNeedAgree;
    },
    cardMainBannerRollingTime(state) {
      return getRollTime(state.placementList, "1");
    },
    loanMainBannerRollingTime(state) {
      return getRollTime(state.placementList, "4");
    },
    insuranceMainBannerRollingTime(state) {
      return getRollTime(state.placementList, "12");
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_PLACEMENT_LIST(state, { placementList }) {
      state.placementList = placementList;
    },
    SET_USER_INFO(state, { custNm, custSeq, userInfo, osType }) {
      state.custNm = custNm;
      state.custSeq = custSeq;
      state.userInfo = { ...userInfo, osType };
    },
    SET_USER_ESSENTIAL_INFO(state, { custNm, custSeq }) {
      state.custNm = custNm;
      state.custSeq = custSeq;
      state.userInfo = { ...state.userInfo, custSeq };
    },
    SET_TRACK_CD(state, { trackCd }) {
      state.trackCd = trackCd;
    },
    SET_TRACK_CD_TYPE(state, { trackCdType }) {
      state.trackCdType = trackCdType;
    },
    SET_PUSH_INFO(state, { pushInfo }) {
      state.pushInfo = pushInfo;
    },
    SET_AGREE_INFO(state, { agreeInfo, isNeedAgree }) {
      state.agreeInfo = agreeInfo;
      state.isNeedAgree = isNeedAgree;
    }
  },
  actions: {
    async setToken({ commit, dispatch }, { token, query } = { query: {} }) {
      commit("SET_TOKEN", token);
      await dispatch("fetchUser", { token, query });
    },
    async fetchUser({ commit, getters }, { token, query } = { query: {} }) {
      const { track_cd, track_cd_type, cust_seq } = query;
      console.log("query", query);

      const trackCdType = track_cd_type ? track_cd_type : getters.trackCdType ? getters.trackCdType : WALK_IN_TRACK_CD_TYPE;
      const custSeq = IS_DEV_ENV && cust_seq ? cust_seq : getters.custNm ? getters.custSeq : null; // 우리쪽 약관 미동의 상태면 null(한번 등록하고 약관 미동의해도 custSeq는 존재한다), token은 1회용으로 조회하는 것이고 5분이면 만료된다. 재조회시 사용하게 된다.

      // API에서 trackCd가 있으면 그대로 리턴하고, 없으면 새로 생성해 준다.
      const { data } = await getOcbUserInfo({
        trackCd: track_cd ? track_cd : getters.trackCd,
        condition: {
          trackCdType,
          token: token ? token : getters.token,
          custSeq
        }
      });
      const { isSucceed, resultMessage, resultCode, result, trackCd } = data;

      if (isSucceed) {
        const { custNm, custSeq, userInfo, agreeInfo, isNeedAgree, placementList } = result;

        // WARN. userInfo에 있는 custNm과 custSeq는 암호화된 값으로 OCB용이다. 내부에서는 쓰면 안된다. (사용자 표출 등)
        // userInfo 외부에 있느 custNm을 사용자 표출하고 custSeq는 내부 통신(ebmp)에 사용해야 한다.
        console.log("custSeq, trackCd, trackCdType", trackCdType, trackCd, custSeq);
        commit("SET_USER_INFO", { custNm, custSeq, userInfo, osType: getters.osType });

        if (!getters.trackCd) {
          commit("SET_TRACK_CD", { trackCd }); // 실제 사용하는 것은 항상 API의 trackCd를 활용한다.
        }

        commit("SET_TRACK_CD_TYPE", { trackCdType });
        commit("SET_AGREE_INFO", { agreeInfo, isNeedAgree });
        commit("SET_PLACEMENT_LIST", { placementList });
      } else {
        showFailMessage(commit, { resultMessage, resultCode });
      }

      return data;
    },
    async setAgreeInfo({ commit, getters }, { agreeInfoForm }) {
      if (!agreeInfoForm) return console.error("agreeInfo is not defined");

      const agreeInfo = {};
      for (let [key, value] of Object.entries(agreeInfoForm)) {
        agreeInfo[key] = value ? "Y" : "N";
      }

      const { data } = await putAgreeInfo({
        trackCd: getters.trackCd,
        condition: {
          ...agreeInfo,
          ...getters.userInfo,
          trackCdType: getters.trackCdType
        }
      });

      const { isSucceed, resultMessage, resultCode, result, trackCd } = data;

      if (isSucceed) {
        let isNeedAgree = false;
        const { trdInfo, trdInfoDate, custNm, custSeq } = result;
        if (trdInfo === "N") {
          isNeedAgree = true;
        }

        // 기존 비가입 고객은 이 프로세스 이후에 trackCd가 발급된다.
        if (!getters.trackCd) {
          commit("SET_TRACK_CD", { trackCd });
        }
        commit("SET_AGREE_INFO", { agreeInfo: { trdInfo, trdInfoDate }, isNeedAgree });
        commit("SET_USER_ESSENTIAL_INFO", { custNm, custSeq });
      } else {
        showFailMessage(commit, { resultCode, resultMessage });
      }

      return data;
    }
  }
};

const { mapState: userState, mapGetters: userGetters, mapActions: userActions } = createNamespacedHelpers("user");

export { userState, userGetters, userActions };
