import { IS_DEV_ENV } from "@/const";

export const keepLastIndex = (obj) => {
  if (window.getSelection) {
    //ie11 10 9 ff safari
    obj.focus(); //Solve the problem that ff cannot locate the focus without getting the focus
    let range = window.getSelection(); //Create range
    range.selectAllChildren(obj); //range selects all child content under obj
    range.collapseToEnd(); //Move the cursor to the end
  } else if (document.selection) {
    //ie10 9 8 7 6 5
    let range = document.selection.createRange(); //Create selection object
    //var range = document.body.createTextRange();
    range.moveToElementText(obj); //range is positioned to obj
    range.collapse(false); //Move the cursor to the end
    range.select();
  }
};

export const convertCamelQueries = (queries) => {
  const result = {};

  const toCamel = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace("_", "");
    });
  };

  Object.keys(queries).forEach((key) => {
    result[toCamel(key)] = queries[key];
  });
  return result;
};

export const setLocalStorageItem = (key, value, ttl) => {
  let item = {
    value: value,
    expiry: ttl ? Date.now() + ttl : null
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItem = (key) => {
  let item = localStorage.getItem(key);

  if (!item) return null;

  item = JSON.parse(item);

  console.log(item.expiry, Date.now());
  if (item.expiry && Date.now() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const cleanObject = (object) => {
  if (!object) return object;

  const copiedObject = { ...object };
  for (let key in copiedObject) {
    if (copiedObject[key] === undefined || copiedObject[key] === null) {
      delete copiedObject[key];
    }
  }
  return copiedObject;
};

export const getRollTime = (placementList, placeSeq) => {
  let rollTime = 5000;

  if (placementList) {
    for (let i = 0; i < placementList.length; i++) {
      const element = placementList[i];
      if (element.placeSeq === placeSeq) {
        // use final element's rollTime
        rollTime = parseInt(element.rollTime, 10) * 1000;
      }
    }
  }

  return rollTime;
};

export const makeUnderScoreCase = (text) => {
  if (!text) return;

  return text
    .trim()
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};

export const makeUnderScoreObject = (obj) => {
  if (!obj) return obj;

  const cloneObj = { ...obj };
  for (const [key, value] of Object.entries(obj)) {
    let newKey = makeUnderScoreCase(key);
    if (value) {
      cloneObj[newKey] = value;
    }

    if (newKey !== key) {
      delete cloneObj[key];
    }
  }

  return cloneObj;
};

export const addSearchQuery = (destinationUrl, queryObject) => {
  const newUrl = new URL(destinationUrl);
  const oldUrl = new URL(document.location);

  // delete the old params in the new params
  newUrl.searchParams.forEach((value, key) => {
    oldUrl.searchParams.delete(key);
  });

  // set the old params to the new params
  oldUrl.searchParams.forEach((value, key) => {
    newUrl.searchParams.set(key, value);
  });

  if (queryObject) {
    for (const [key, value] of Object.entries(queryObject)) {
      if (value) {
        newUrl.searchParams.set(key, value);
      } else {
        newUrl.searchParams.delete(key);
      }
    }
  }

  return newUrl.toString();
};

export const isOurService = (destinationUrl) => {
  if (IS_DEV_ENV) {
    return destinationUrl.indexOf("localhost") >= 0 || destinationUrl.indexOf(process.env.VUE_APP_URI) >= 0;
  } else {
    return destinationUrl.indexOf(process.env.VUE_APP_URI) >= 0;
  }
};

export const getQueryLength = (destinationUrl) => {
  if (!destinationUrl) return 0;

  return Array.from(new URL(destinationUrl).searchParams).length;
};

export const getToday = () => {
  return new Date().toISOString().substr(0, 10);
};

export const isSuccess = (data) => {
  if (!data || !data.isSucceed) return false;
  return true;
};

export const isEmptyObject = (object) => {
  if (!object) return true;

  return Object.keys(object).length === 0;
};

export const isEmptyList = (list) => {
  if (!list) return true;

  return list.length === 0;
};

export const isResSuccess = (res) => {
  if (!res || !res.data || !res.data.isSucceed) return false;
  return true;
};
