import { createNamespacedHelpers } from "vuex";
import * as api from "@/api";
import { showFailMessage } from "@/api/handler.js";

export default {
  state: {
    promotion: {
      detailItem: {},
      mappedLoanList: []
    },
    summary: {},
    description: {},
    footer: {}
  },
  getters: {
    promotion: (state) => {
      return state.promotion;
    },
    summary: (state) => {
      const { companyDetailImgPath, compNm, desc1, desc2, proName, outLinkBtnNm, outLinkUrl, outLinkType } = state.promotion.detailItem;

      return {
        proName,
        companyDetailImgPath,
        compNm,
        desc1,
        desc2,
        outLinkBtnNm,
        outLinkUrl,
        outLinkType
      };
    },
    description: (state) => {
      const { detailItem } = state.promotion;

      return detailItem;
    },
    cardList: (state) => {
      const { mappedLoanList } = state.promotion;

      return mappedLoanList;
    },
    isCardList: (state) => {
      const { mappedLoanList } = state.promotion;

      return mappedLoanList && mappedLoanList.length > 0;
    },
    footer: (state) => {
      const { proFooterTitle, proFooterInformation, licenseNo1, licenseNo2, proAttention } = state.promotion.detailItem;

      return {
        proFooterTitle: proFooterTitle,
        proFooterInformation: proFooterInformation,
        licenseNo1: licenseNo1,
        licenseNo2: licenseNo2,
        proAttention: proAttention
      };
    }
  },
  mutations: {
    SET_LOAN_PROMOTION(state, { promotion }) {
      state.promotion = promotion;
    }
  },
  actions: {
    async fetchLoanPromotion({ commit, rootGetters }, { promotionSeq }) {
      const { data } = await api.getLoanPromotion({
        trackCd: rootGetters["user/trackCd"],
        condition: {
          osType: rootGetters["user/osType"],
          promotionSeq: promotionSeq
        }
      });

      const { isSucceed, resultMessage, resultCode, result } = data;

      if (isSucceed) {
        if (!result) return;

        commit("SET_LOAN_PROMOTION", { promotion: result });
      } else {
        showFailMessage(commit, { resultMessage, resultCode });
      }

      return data;
    }
  }
};

const { mapGetters: loanPromotionGetters, mapActions: loanPromotionActions } = createNamespacedHelpers("loan/promotion");

export { loanPromotionGetters, loanPromotionActions };
