<template>
  <li class="dialog-push-insurance-item" :data-image-path="imgPath" :data-insurance-name="insuName" :data-support-text="supportText" :data-category-name-list="categoryNameList" @click="onClick">
    <div class="img-wrap">
      <div class="img-outer">
        <div class="img-inner">
          <img v-if="imgPath" :src="imgPath" :alt="`${insuName} 이미지`" />
        </div>
      </div>
    </div>
    <div class="summary-info">
      <strong class="name">{{ insuName }}</strong>
      <ul class="category-list">
        <li class="category-item" v-for="(category, index) in categoryNameList" :key="index">{{ category }}</li>
      </ul>
    </div>
    <em class="support" v-if="supportText"><i class="icon icon-money"></i> {{ supportText }} 지급</em>
  </li>
</template>

<script>
export default {
  name: "DialogPushCard",
  props: {
    imgPath: {
      type: String,
      default: null
    },
    insuName: {
      type: String,
      default: ""
    },
    supportText: {
      type: String,
      default: ""
    },
    categoryNameList: {
      type: Array,
      default: () => []
    },
    onClickItem: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isView: false
    };
  },
  methods: {
    onClick(e) {
      this.$emit("click-item", e);
    }
  },
  mounted() {
    this.$nextTick(() => {
      //Todo 렌더링 시점 타이머 수정 필요
      setTimeout(() => {
        const el = this.$el;
        const position = el.getBoundingClientRect();

        this.isView = position.top < window.innerHeight;

        if (this.isView) {
          this.$emit("display-item", el);
        }
      }, 500);
    });
  }
};
</script>

<style scoped></style>
